import { useState } from 'react';
import styles from './style.module.scss';
import ChangesLog from 'components/atoms/ChangesLog';

export default function InputTextAreaLog({
  id,
  disabled,
  key = '',
  name,
  placeholder,
  value = '',
  prevValue,
  maxLength = 524288,
  onChange,
  disabled_text,
  wasChanged,
  approved,
  changed,
  dismiss,
  rows = 2
}) {
  const [showLog, setShowLog] = useState(false);

  const applyClass = wasChanged
    ? approved
      ? styles.approved
      : dismiss
      ? styles.dismiss
      : changed
      ? styles.changed
      : styles.requireReview
    : approved
    ? styles.approved
    : dismiss
    ? styles.dismiss
    : changed
    ? styles.changed
    : '';

  return (
    <div className={styles.container} key={key}>
      <textarea
        className={applyClass}
        id={id}
        key={key}
        name={name}
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={value}
        onChange={onChange}
        maxLength={maxLength}
        rows={rows}
      />
      {wasChanged && prevValue && (
        <ChangesLog
          showLog={showLog}
          setShowLog={setShowLog}
          prevValue={prevValue}
          className="absolute top-[24px] right-[16px]"
        />
      )}
    </div>
  );
}
