import Arrow from '../../../public/images/rounded-down-arrow.svg';
import { useContext, useEffect } from 'react';
import PartnersContext from '../../../context/PartnerListingsProvider';
import { useTranslation } from 'react-i18next';
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger
} from 'components/ui/popover';
import { Button } from 'components/ui/button';
import { Command, CommandGroup, CommandItem } from 'components/ui/command';

export const LanguageSwitcher = () => {
  const { setLanguage, language, setIsLoadingMenu } =
    useContext(PartnersContext);
  const { i18n } = useTranslation();

  useEffect(
    () => setLanguage(i18n.language === 'en' ? 'en-us' : i18n.language),
    [i18n.language]
  );

  const optionsLanguages = [
    {
      key: 1,
      text: 'English',
      isoCodes: ['en-US'],
      onClick: () => {
        i18n.changeLanguage('en-US');
        setIsLoadingMenu(true);
        window.location.reload();
      }
    },
    {
      key: 2,
      text: '中国人',
      isoCodes: ['cn'],
      onClick: () => {
        i18n.changeLanguage('cn');
        setIsLoadingMenu(true);
        window.location.reload();
      }
    },
    {
      key: 3,
      text: '日本',
      isoCodes: ['ja'],
      onClick: () => {
        i18n.changeLanguage('ja');
        setIsLoadingMenu(true);
        window.location.reload();
      }
    },
    {
      key: 4,
      isoCodes: ['pt', 'pt-PT'],
      text: 'Português',
      onClick: () => {
        i18n.changeLanguage('pt');
        setIsLoadingMenu(true);
        window.location.reload();
      }
    },
    {
      key: 5,
      text: 'Español',
      isoCodes: ['es'],
      onClick: () => {
        i18n.changeLanguage('es');
        setIsLoadingMenu(true);
        window.location.reload();
      }
    }
  ].filter(
    (option) =>
      !option?.isoCodes?.some(
        (iso) => iso?.toLowerCase() === i18n?.language?.toLowerCase()
      )
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="unstyled"
          className="text-white font-extralight text-sm p-0 h-auto tracking-normal"
        >
          {language.toUpperCase()}
          <Arrow />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="p-0 bg-[#1f384c] rounded-sm"
        side="bottom"
        align="end"
      >
        <Command className="bg-transparent p-0">
          <CommandGroup className="p-0 text-start">
            {optionsLanguages.map((option) => (
              <CommandItem
                key={option.key}
                onSelect={option.onClick}
                className="px-4 py-4 text-white font-normal text-xs rounded-none cursor-pointer aria-selected:bg-[#1f384c] aria-selected:text-white aria-selected:hover:bg-white aria-selected:hover:text-[#1f384c]"
              >
                {option.text}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
        <PopoverArrow className="fill-[#1f384c] ml-8" />
      </PopoverContent>
    </Popover>
  );
};
