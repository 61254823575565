import ModerationButtons from 'components/atoms/ModerationButtons';
import { DatePicker } from 'components/ui/date-picker';
import { cn } from 'lib/utils';

type ComponentProps = {
  label?: string;
  labelClassname?: string;
  id: string;
  min?: any;
  max?: any;
  disabled?: boolean;
  placeholder?: string;
  description?: string;
  value: any;
  required?: boolean;
  onChange: (a: any, b?: any) => void;
  error?: string;
  insidePortal?: boolean;
  allowClear?: boolean;
  allowRevert?: boolean;
  highlight?: boolean;
  size?: 'small' | 'medium' | 'input';
  prevValue?: string;
  wasChanged?: boolean;
  approve?: boolean;
  approved?: boolean;
  changed?: boolean;
  dismiss?: boolean;
  onChangeApprove?: (id: string, type: number) => void;
  className?: string;
};

export default function InputDate({
  label = '',
  id,
  min,
  max,
  disabled,
  placeholder = '--/--/----',
  description,
  value = '',
  required,
  onChange,
  error,
  insidePortal = false,
  allowRevert = false,
  allowClear = false,
  highlight = false,
  size,
  prevValue,
  wasChanged = false,
  approve = false,
  approved = false,
  changed = false,
  dismiss = false,
  onChangeApprove = () => {},
  labelClassname = '',
  className = ''
}: ComponentProps) {
  return (
    <fieldset className={cn('w-full', className)}>
      <div className={'flex items-center justify-between gap-2'}>
        {label ? (
          <label
            className={cn('flex items-center text-base', labelClassname)}
            htmlFor={id}
          >
            {label}
            {required && (
              <span
                className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}
              >
                *
              </span>
            )}
          </label>
        ) : null}
        {approve && wasChanged ? (
          <ModerationButtons
            id={id}
            onChangeApprove={onChangeApprove}
            approved={approved}
            dismissed={dismiss}
          />
        ) : null}
      </div>
      {description && <small> {description}</small>}
      <DatePicker
        id={id}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => onChange(e, id)}
        min={min ? min : undefined}
        max={max ? max : undefined}
        triggerClassName="w-full"
        insidePortal={insidePortal}
        allowRevert={allowRevert}
        allowClear={allowClear}
        highlight={highlight}
        size={size}
        prevValue={prevValue}
        wasChanged={wasChanged}
        approved={approved}
        changed={changed}
        dismiss={dismiss}
      />
      {error && <span>{error}</span>}
    </fieldset>
  );
}
