import { useEffect, useState } from 'react';

import InputTextAreaLog from '../InputTextAreaLog';
import styles from './style.module.scss';
import ModerationButtons from 'components/atoms/ModerationButtons';
import { Trim } from 'shared/utils';

type ComponentProps = {
  label?: any;
  id: string;
  key?: any;
  disabled?: boolean;
  name?: any;
  placeholder?: any;
  description?: any;
  value?: any;
  prevValue?: any;
  required?: any;
  maxWords?: Number;
  fullWidth?: any;
  maxLength?: any;
  onChange?: any;
  onChangeApprove?: any;
  onError?: any;
  checkErrorOnFirstRender?: any;
  wasChanged?: any;
  hasNote?: any;
  approve?: any;
  approved?: any;
  changed?: any;
  dismiss?: any;
  rows?: any;
};

export default function InputTextAreaPV({
  label,
  id,
  key = '',
  disabled,
  name,
  placeholder,
  description,
  value = '',
  prevValue,
  required,
  fullWidth = false,
  maxLength = 524288,
  maxWords,
  onChange,
  onChangeApprove,
  onError,
  checkErrorOnFirstRender = false,
  wasChanged,
  hasNote = null,
  approve,
  approved,
  changed,
  dismiss,
  rows
}: ComponentProps) {
  const inputValue = value ? value : '';
  const [checkError, setCheckError] = useState(checkErrorOnFirstRender);
  const [error, setError] = useState(
    checkErrorOnFirstRender && required && Trim(inputValue) === ''
  );

  const maxWordsCheck = maxWords
    ? Trim(inputValue)?.split(' ').length > maxWords
    : false;

  useEffect(() => {
    const hasError = (required && Trim(inputValue) === '') || maxWordsCheck;
    setError(checkError && hasError);
    onError && onError(hasError, id);
    setCheckError(true);
  }, [inputValue, required, maxWordsCheck]);

  const requiredLabels = label?.includes('*')
    ? label?.substring(label?.indexOf('*'), label?.lastIndexOf('*') + 1)
    : '';

  return (
    <fieldset
      key={key}
      className={`${styles.fieldset} ${fullWidth ? styles.full_width : ''} ${
        error ? 'error' : ''
      }`}
    >
      <div className={styles.labels_and_moderation}>
        <label htmlFor={id} className={styles.label}>
          {`${label?.replaceAll('*', '')}`}
          {required && (
            <span
              className={'text-red-500 ml-[2px] leading-[18px] text-[16px]'}
            >
              * {requiredLabels}
            </span>
          )}
          {hasNote && <span className={styles.hasNote}>{hasNote}</span>}
          {description && <small> {description}</small>}
        </label>
        {approve && wasChanged && (
          <ModerationButtons
            id={id}
            onChangeApprove={onChangeApprove}
            approved={approved}
            dismissed={dismiss}
          />
        )}
      </div>
      <InputTextAreaLog
        id={id}
        key={key}
        disabled={disabled}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={onChange}
        prevValue={prevValue}
        disabled_text={styles.disabled_text}
        maxLength={maxLength}
        wasChanged={wasChanged}
        approved={approved}
        changed={changed}
        dismiss={dismiss}
        rows={rows}
      />
      {error && (
        <span>{maxWordsCheck ? 'Words limit exceeded' : 'Required field'}</span>
      )}
    </fieldset>
  );
}
