'use client';
import { Calendar as CalendarIcon, X } from 'lucide-react';
import { cn } from 'lib/utils';
import { Dispatch, SetStateAction, useState } from 'react';
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger
} from './popover';
import { Button } from './button';
import { Calendar } from './calendar';
import { convertDateToFormat, formatDateUTC } from 'shared/utils';
import ChangesLog from 'components/atoms/ChangesLog';

export function DatePicker({
  id,
  value,
  onChange,
  disabled = false,
  placeholder,
  triggerClassName = '',
  min,
  max,
  allowRevert = false,
  allowClear = false,
  size = 'medium',
  insidePortal = false,
  highlight = false,
  prevValue,
  wasChanged = false,
  approved = false,
  changed = false,
  dismiss = false
}: {
  id: string;
  disabled?: boolean;
  value: Date | undefined;
  onChange: Dispatch<SetStateAction<Date | undefined>>;
  placeholder: string;
  triggerClassName?: string;
  min: Date | undefined;
  max: Date | undefined;
  allowRevert?: boolean;
  allowClear?: boolean;
  size?: 'small' | 'medium' | 'input';
  insidePortal?: boolean;
  highlight?: boolean;
  prevValue?: string;
  wasChanged?: boolean;
  approved?: boolean;
  changed?: boolean;
  dismiss?: boolean;
}) {
  const [open, setOpen] = useState(false);
  const sizes = {
    small: { input: 'text-sm px-3 py-0 h-10', icon: 'h-4 w-4' },
    medium: { input: 'text-base px-3 py-0 h-12', icon: 'h-5 w-5' },
    input: { input: 'text-base px-3 py-0 h-[54px] mt-[5px]', icon: 'h-5 w-5' }
  };

  const status = {
    approved:
      'rounded border-solid border-[#6fcf97] pr-12 focus:shadow-none hover:shadow-none focus:border-2 focus:border-solid focus:border-[#6fcf97]',
    dismiss:
      'rounded border-solid border-[#eb5757] pr-12 focus:shadow-none hover:shadow-none focus:border-2 focus:border-solid focus:border-[#eb5757]',
    changed:
      'rounded border-solid border-[#f2c94c] pr-12 focus:shadow-none hover:shadow-none focus:border-2 focus:border-solid focus:border-[#f2c94c]',
    requireReview:
      'bg-[#f0b51a33] hover:bg-[#f0b51a33] border-2 border-solid border-[#f0b51a]'
  };

  const applyClass = wasChanged
    ? approved
      ? status.approved
      : dismiss
      ? status.dismiss
      : changed
      ? status.changed
      : status.requireReview
    : approved
    ? status.approved
    : dismiss
    ? status.dismiss
    : '';

  const selectedValue: any = value ? convertDateToFormat(value, false) : '';

  const hasPreviousValue = wasChanged && prevValue;
  const hasClearAction = allowClear && value;

  return (
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant="outline"
          className={cn(
            'relative justify-start text-left font-light text-black h-auto tracking-normal',
            sizes[size].input,
            'hover:shadow-[inset_0_0_0_1px_#b5bfc9] hover:bg-white disabled:bg-[#eeeeee] disabled:text-[#97999b]',
            value ? '' : 'text-muted-foreground',
            highlight ? 'border-none bg-[#fdefc3] hover:bg-[#fdefc3]' : '',
            hasPreviousValue || hasClearAction ? 'pr-9' : '',
            applyClass,
            triggerClassName
          )}
          disabled={disabled}
        >
          {value ? <CalendarIcon className={sizes[size].icon} /> : null}
          {value ? formatDateUTC(value) : <span>{placeholder}</span>}
          {hasPreviousValue || hasClearAction ? (
            <div className="absolute top-1/2 translate-y-[-50%] right-4 flex gap-2">
              {hasPreviousValue ? (
                <ChangesLog
                  showLog={open}
                  setShowLog={setOpen}
                  prevValue={prevValue}
                />
              ) : null}
              {hasClearAction ? (
                <PopoverClose asChild>
                  <X
                    className="p-0 m-0 hover:cursor-pointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(undefined);
                    }}
                  />
                </PopoverClose>
              ) : null}
            </div>
          ) : null}
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className="w-auto p-0 border border-solid border-gray-200"
        noPortal={!insidePortal}
      >
        <Calendar
          id={id}
          mode="single"
          fromDate={min}
          toDate={max}
          defaultMonth={value ? selectedValue : undefined}
          selected={value ? selectedValue : undefined}
          onSelect={(e) =>
            e ? onChange(e) : allowRevert ? onChange(undefined) : undefined
          }
          initialFocus
          fixedWeeks
        />
      </PopoverContent>
    </Popover>
  );
}
