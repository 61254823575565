import styles from './style.module.scss';
import { useState } from 'react';
import { api } from '../../../../services/api';
import InputText from '../../InputText';
import SelectPV from '../../../atoms/SelectPV/SelectPV';
import InputTextAreaPV from '../../../molecules/InputTextAreaPV';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { useQuery } from '@tanstack/react-query';
import { getAPIClient } from '../../../../services/axios';
import IconTrash from '../../../../public/images/icon-trash.svg';
import { toast } from 'sonner';
import InputDate from 'components/molecules/InputDate';
import { getNewDate } from 'shared/utils';
import { useEventsContext } from 'hooks/useContexts';

export default function NewReleaseForm({
  open,
  setOpen,
  changeSetOpen,
  selectedRelease = null,
  setSelectedRelease,
  initialFormState,
  category,
  feature,
  newRelease,
  setRefetch
}) {
  const { t } = useTranslation();
  const { usersTimezoneLocation } = useEventsContext();
  const [refreshKey, setRefreshKey] = useState(0);
  const [buttonDisable, setButtonDisable] = useState(false);

  const all_roles = {
    id: 0,
    value: 'select-all',
    label: 'All Roles'
  };

  const addCategory = () => {
    setSelectedRelease((prev) => ({
      ...prev,
      categories: [...prev.categories, category]
    }));
  };

  const addFeature = (index) => {
    setSelectedRelease((prev) => {
      const featuresUpdated = [...prev.categories[index].features, feature];

      return {
        ...prev,
        categories: [
          ...prev.categories.slice(0, index),
          {
            ...prev.categories[index],
            features: featuresUpdated
          },
          ...prev.categories.slice(index + 1)
        ]
      };
    });
  };

  const { data: rolesData } = useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const res = await getAPIClient().get(`/roles`);
      return res.data;
    }
  });
  const roles = [all_roles].concat(
    rolesData?.map((role) => {
      return { id: role.id, label: role.name, value: role.name };
    }) || []
  );

  const onChangeRole = (index, ind, newArr) => {
    const previousArr = selectedRelease.categories[index].features[ind].roles;

    const addOrDelete =
      previousArr?.length - newArr?.length === 1 || newArr?.length === 0
        ? 'delete'
        : 'add';

    let newRoles = [];
    if (addOrDelete === 'delete') {
      newRoles = newArr;
    } else if (addOrDelete === 'add') {
      if (newArr.some((e) => e.label === 'All Roles')) {
        newRoles = [all_roles];
      } else {
        newRoles = newArr;
      }
    }

    setSelectedRelease((prev) => {
      const featuresUpdated = [...prev.categories[index].features];
      featuresUpdated[ind].roles = newRoles;
      featuresUpdated[ind].visibleToAll = newArr.some(
        (e) => e.label === 'All Roles'
      )
        ? true
        : false;

      return {
        ...prev,
        categories: [
          ...prev.categories.slice(0, index),
          {
            ...prev.categories[index],
            features: featuresUpdated
          },
          ...prev.categories.slice(index + 1)
        ]
      };
    });
  };

  async function handleSave() {
    try {
      api.put(`/releases`, { selectedRelease, newRelease }).then(() => {
        setSelectedRelease(initialFormState);
        changeSetOpen(false);
        setOpen(false);
        setRefetch((prev) => !prev);
        toast.success('Release was added.');
      });
    } catch (error) {
      toast.error('Error adding the release', { description: error || '' });
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const disabled =
    !selectedRelease.date ||
    selectedRelease.categories.some((cat) => !cat.category) ||
    selectedRelease.categories.some((cat) =>
      cat.features.some((f) => !f.feature)
    ) ||
    selectedRelease.categories.some((cat) =>
      cat.features.some((f) => f.roles.length === 0 && !f.visibleToAll)
    );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="md:p-0 gap-0 md:max-w-5xl h-auto">
        <DialogHeader className="bg-[#2360A0] rounded-t-[23px]">
          <DialogTitle className="text-[#fff] text-[20px] p-6">
            {newRelease ? t('new_release') : t('update_release')}
          </DialogTitle>
        </DialogHeader>
        <div className={styles.content}>
          <Button icon="add" onClick={() => addCategory()}>
            {t('category')}
          </Button>
          <div className={styles.categories}>
            <InputDate
              id="date"
              placeholder={t('date')}
              key="date"
              label={t('date')}
              required
              value={selectedRelease.date}
              onChange={(e) => {
                setSelectedRelease((prev) => ({
                  ...prev,
                  date: getNewDate({
                    date: e,
                    getISODateFromUsersTimezone: true,
                    usersTimezoneLocation: usersTimezoneLocation,
                    removeTimezone: true
                  })
                }));
              }}
              size="input"
            />

            {selectedRelease?.categories?.map((cat, index) => (
              <div key={`${refreshKey}_${index}`}>
                <fieldset
                  key={`release_${index}`}
                  className={`w-full mt-5 ${styles.feature_input}`}
                >
                  <InputText
                    id={`category_${index}`}
                    placeholder={t('category')}
                    key={`category_${index}`}
                    label={t('category')}
                    type={'text'}
                    required
                    value={selectedRelease.categories[index].category}
                    onChange={(e) => {
                      setSelectedRelease((prev) => {
                        const categoriesUpdated = [...prev.categories];
                        categoriesUpdated[index].category = e?.target?.value;
                        return {
                          ...prev,
                          categories: categoriesUpdated
                        };
                      });
                    }}
                  />
                  <Button
                    className={'w-1/3'}
                    icon="add"
                    onClick={() => addFeature(index)}
                  >
                    {t('feature')}
                  </Button>
                  <Button
                    variant="ghost"
                    key={`trash_cat_${index}`}
                    onClick={() => {
                      setSelectedRelease((prev) => {
                        const categoriesUpdated = [...prev.categories];
                        categoriesUpdated.splice(index, 1);
                        return {
                          ...prev,
                          categories: categoriesUpdated
                        };
                      });
                      setRefreshKey((prev) => prev + 1);
                    }}
                    className={styles.remove_button}
                    disabled={selectedRelease.categories.length === 1}
                  >
                    <IconTrash />
                  </Button>
                </fieldset>

                {selectedRelease?.categories[index]?.features?.map(
                  (feat, ind) => (
                    <fieldset
                      key={`release_${index}_${ind}`}
                      className="w-full flex justify-between gap-5 pt-5"
                    >
                      <fieldset className={`w-full ${styles.feature_input}`}>
                        <InputTextAreaPV
                          id={`feature_${index}_${ind}`}
                          placeholder={t('feature')}
                          key={`feature_${index}_${ind}`}
                          label={t('feature')}
                          type={'text'}
                          required
                          rows={3}
                          value={
                            selectedRelease.categories[index].features[ind]
                              .feature
                          }
                          onChange={(e) => {
                            setSelectedRelease((prev) => {
                              const featuresUpdated = [
                                ...prev.categories[index].features
                              ];
                              featuresUpdated[ind].feature = e?.target?.value;
                              return {
                                ...prev,
                                categories: [
                                  ...prev.categories.slice(0, index),
                                  {
                                    ...prev.categories[index],
                                    features: featuresUpdated
                                  },
                                  ...prev.categories.slice(index + 1)
                                ]
                              };
                            });
                          }}
                        />
                      </fieldset>
                      <fieldset className="w-full">
                        <SelectPV
                          id={`role_${index}_${ind}`}
                          label={t('role')}
                          key={`role_${index}_${ind}`}
                          data={
                            selectedRelease.categories[index].features[
                              ind
                            ].roles.some((e) => e.label === 'All Roles') ||
                            (selectedRelease.categories[index].features[ind]
                              .roles.length === 0 &&
                              selectedRelease.categories[index].features[ind]
                                .visibleToAll)
                              ? []
                              : roles
                          }
                          value={
                            (selectedRelease.categories[index].features[ind]
                              .roles.length === 0 &&
                              selectedRelease.categories[index].features[ind]
                                .visibleToAll) ||
                            selectedRelease.categories[index].features[
                              ind
                            ].roles.some((e) => e.label === 'All Roles')
                              ? all_roles
                              : selectedRelease.categories[index].features[
                                  ind
                                ].roles.map((role) => {
                                  return {
                                    id: role.id,
                                    value: role.name ? role.name : role.value,
                                    label: role.name ? role.name : role.value
                                  };
                                })
                          }
                          required
                          onChange={(e) => onChangeRole(index, ind, e)}
                          placeholder="Choose Role"
                          isMultiple={true}
                          menuPlacement="bottom"
                        />
                      </fieldset>
                      <div>
                        <Button
                          variant="ghost"
                          key={`trash_${index}_${ind}`}
                          onClick={() => {
                            setSelectedRelease((prev) => {
                              const categoriesUpdated = [...prev.categories];
                              categoriesUpdated[index].features.splice(ind, 1);
                              return {
                                ...prev,
                                categories: categoriesUpdated
                              };
                            });
                            setRefreshKey((prev) => prev + 1);
                          }}
                          className={styles.remove_button}
                          disabled={
                            selectedRelease.categories[index].features
                              .length === 1
                          }
                        >
                          <IconTrash />
                        </Button>
                      </div>
                    </fieldset>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
        <DialogFooter className="h-10 justify-end p-8 w-auto">
          <Button
            variant="ghost"
            onClick={() => {
              setOpen(false);
              setSelectedRelease(initialFormState);
            }}
            className={styles.buttons}
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={() => {
              setButtonDisable(true);
              handleSave();
            }}
            className={styles.buttons}
            disabled={disabled || buttonDisable}
          >
            {t('save')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
